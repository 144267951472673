body,
form textarea {
  background-color: #fff
}

.Y-Center-inner,
img {
  vertical-align: middle
}

.XY-Center,
.Y-Center {
  position: absolute;
  top: 50%
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans", Arial, sans-serif
}

body,
h5 {
  line-height: 22px
}

body,
p {
  color: #333
}

.btn-inner,
.btn-outer:focus,
.btn-outer:hover,
header nav .navigaitonBtns li a,
header nav .navigaitonBtns li a:hover {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#55bbfd, endColorstr=#1d92de)
}

.megaMenu:after,
header:after {
  bottom: 0;
  content: ""
}

*,
.financial-glossary .glossary-search form input:focus,
:focus,
a,
a:focus,
a:hover {
  outline: 0
}

* {
  margin: 0;
  padding: 0
}

body,
html {
  height: 100%
}

body {
  font-size: 15px;
  padding-top: 74px
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  border: none
}

form input[type=text]:focus,
form input[type=email]:focus,
form textarea:focus {
  border: 1px solid #000
}

form input[type=text],
form input[type=email],
form input[type=password],
form input[type=search],
form select {
  color: #000;
  font-size: 14px;
  //padding: 0 10px;
  padding: 0 10px 0 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  height: 35px;
  line-height: 35px;
  border-radius: 3px
}

form textarea {
  color: #000;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  resize: none
}

.contacts-bar > ul > li > a,
.contacts-bar > ul > li > a.open {
  background-color: #b39ddb
}

.Y-Center-outer {
  display: table;
  height: 100%
}

.Y-Center-inner {
  display: table-cell
}

.Y-Center {
  -webkit-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0)
}

.XY-Center {
  text-align: center;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0)
}

.space {
  padding: 60px 0
}

.container {
  z-index: 15;
  position: relative
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000
}

h1 {
  font-size: 50px;
  line-height: 64px
}

h2 {
  font-size: 40px;
  line-height: 50px
}

h3 {
  font-size: 28px;
  line-height: 36px
}

h4 {
  font-size: 19px;
  line-height: 27px
}

h5 {
  font-size: 17px
}

h6 {
  font-size: 14px;
  line-height: 18px
}

blockquote,
p {
  font-size: 14px
}

.CybotCookiebotDialogBodyContentText p {
  font-size: 12.8px
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0
}

strong {
  font-weight: 700
}

a {
  color: #0775BC;
  text-decoration: none;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s
}

.btn-theme,
.btn-theme-lg {
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s
}

a:focus,
a:hover {
  text-decoration: underline
}

p {
  margin-bottom: 20px;
  line-height: 1.5
}

blockquote {
  border: none;
  border-left: 4px solid #000;
  padding: 10px 0 10px 20px;
  letter-spacing: .1em;
  text-align: left
}

.slogan,
.title {
  text-align: center
}

.title {
  margin: 0 0 20px;
  font-size: 30px;
  line-height: 38px;
  color: #000;
  font-weight: lighter
}

.title span {
  font-weight: 700
}

.slogan {
  margin: 0 0 25px;
  font-size: 15px;
  font-weight: lighter
}

.text-bold {
  font-weight: 700
}

em {
  font-style: italic
}

.btn-outer {
  border: 1px solid #62a7d5;
  color: #62a7d5
}

.btn-outer:focus,
.btn-outer:hover {
  color: #fff;
  text-decoration: none;
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

.btn-inner {
  border: 1px solid #84b9e1;
  color: #fff;
  text-decoration: none;
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

.btn-inner:focus,
.btn-inner:hover {
  color: #fff
}

.btn-theme {
  border-radius: 3px;
  padding: 7px 13px;
  transition: all .4s
}

.btn-theme-lg {
  border-radius: 100px;
  padding: 15px 30px;
  font-size: 18px;
  transition: all .4s
}

.contacts-bar > ul > li > .bar-content,
.sep span {
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s
}

.btn-theme i {
  margin: 0 7px 0 0
}

hr {
  margin: 0
}

.page-title {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 36px;
}

.contacts-bar {
  position: fixed;
  right: 0;
  top: 25%;
  z-index: 100
}

.contacts-bar > ul > li {
  position: relative;
  margin-bottom: 2px;
  overflow: hidden;
  padding-left: 2px
}

.contacts-bar ul li:last-of-type {
  border: 0
}

.contacts-bar > ul > li > a {
  display: block;
  height: 41px;
  width: 41px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  font-size: 20px
}

.contacts-bar > ul > li > .bar-content {
  opacity: 0;
  background-color: #b39ddb;
  position: absolute;
  top: 50px;
  left: -250px;
  width: 250px;
  height: 41px;
  padding: 11px 15px 10px;
  color: #fff;
  z-index: 5;
  transition: all .4s
}

.contacts-bar > ul > li:hover {
  overflow: initial
}

.contacts-bar > ul > li:hover .bar-content {
  opacity: 1;
  top: 0;
  z-index: 10
}

.contacts-bar > ul > li > .bar-content h4 {
  font-size: 16px;
  color: #fff;
  padding: 0 0 4px;
  font-weight: 700;
  border-bottom: 1px solid rgba(255, 255, 255, .35)
}

.contacts-bar > ul > li > .bar-content ul li {
  padding: 12px 0;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, .35)
}

.contacts-bar > ul > li > .bar-content ul li:last-of-type {
  border: 0;
  padding-bottom: 0
}

.contacts-bar > ul > li .bar-content h5 {
  font-size: 18px;
  line-height: 20px;
  color: #fff
}

.contacts-bar > ul > li .bar-content h5 i {
  margin-right: 4px
}

.contacts-bar > ul > li .bar-content p {
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  margin: 0
}

.contacts-bar > ul > li .bar-content a {
  color: #fff;
  font-weight: 700
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  background-color: #fff;
  border-top: 2px solid transparent
}

header > .container {
  background-color: #fff;
  z-index: 100
}

header .logo img {
  height: 54px;
  margin: 10px 0 0
}

header:after {
  position: absolute;
  z-index: -1;
  top: 100%;
  width: 150%;
  height: 25px;
  left: -55%;
  background: radial-gradient(ellipse at 70% -45%, rgba(0, 0, 0, .2), rgba(97, 97, 97, 0) 40%)
}

header nav {
  text-align: right
}

header nav .navigaiton {
  display: inline-block;
  font-size: 0
}

header nav .navigaiton li {
  display: inline-block;
  margin: 20px 0
}

header nav .navigaiton li a {
  display: block;
  padding: 5px 15px;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid transparent;
  color: #333
}

header nav .navigaiton li a:hover {
  color: #84b9e1;
  text-decoration: none
}

header nav .navigaitonBtns {
  display: inline-block;
  font-size: 0;
  margin: 0 0 0 30px
}

header nav .navigaitonBtns li {
  display: inline-block;
  margin: 20px 3px
}

header nav .navigaitonBtns li a {
  display: block;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 3px;
  text-transform: uppercase;
  border: 1px solid #84b9e1;
  color: #fff;
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

//Uncomment when in header the are 2 btns (Apply)
header nav .navigaitonBtns li:first-child a {
  background: 0 0;
  color: #84b9e1
}

header nav .navigaitonBtns li:last-of-type a:hover {
  text-decoration: underline
}

header nav .navigaitonBtns li a:hover {
  border-color: #62a7d5;
  color: #fff;
  text-decoration: none;
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

header nav .megaMenuBtn {
  width: 30px;
  height: 17px;
  position: relative;
  top: 4px;
  margin-left: 15px;
  display: inline-block;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer
}

header nav .megaMenuBtn span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #0775bc;
  opacity: 1;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out
}

header nav .megaMenuBtn span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0
}

header nav .megaMenuBtn span:nth-child(odd) {
  left: 0;
  border-radius: 9px 0 0 9px
}

header nav .megaMenuBtn span:nth-child(1),
header nav .megaMenuBtn span:nth-child(2) {
  top: 0
}

header nav .megaMenuBtn span:nth-child(3),
header nav .megaMenuBtn span:nth-child(4) {
  top: 7px
}

header nav .megaMenuBtn span:nth-child(5),
header nav .megaMenuBtn span:nth-child(6) {
  top: 14px
}

header nav .megaMenuBtn.open span:nth-child(1),
header nav .megaMenuBtn.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg)
}

header nav .megaMenuBtn.open span:nth-child(2),
header nav .megaMenuBtn.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

header nav .megaMenuBtn.open span:nth-child(1) {
  left: 5px;
  top: 5px
}

header nav .megaMenuBtn.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 5px
}

header nav .megaMenuBtn.open span:nth-child(3) {
  left: -50%;
  opacity: 0
}

header nav .megaMenuBtn.open span:nth-child(4) {
  left: 100%;
  opacity: 0
}

header nav .megaMenuBtn.open span:nth-child(5) {
  left: 5px;
  top: 10px
}

header nav .megaMenuBtn.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 10px
}

.megaMenu {
  padding: 30px 0;
  position: fixed;
  top: -210px;
  background-color: #fff;
  border-top: 1px solid #f3f3f3;
  width: 100%;
  z-index: 10;
  opacity: 0;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out
}

.megaMenu img {
  padding: 0 10px
}

.megaMenu:after {
  position: absolute;
  z-index: -1;
  top: 100%;
  width: 150%;
  height: 25px;
  left: -55%;
  background: radial-gradient(ellipse at 70% -45%, rgba(0, 0, 0, .2), rgba(97, 97, 97, 0) 40%)
}

.megaMenu.open {
  top: 74px;
  opacity: 1
}

.megaMenu h4 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: lighter;
  margin: 0 0 10px
}

.megaMenu ul li a {
  padding: 2px 0;
  display: block;
  color: #333
}

.megaMenu ul li a:hover {
  color: #84b9e1;
  text-decoration: none
}

.search {
  display: none;
  min-height: 74px;
  position: relative
}

.intro,
.intro .intro-fixed .carousel .item {
  min-height: 450px
}

.search.open {
  display: block
}

.search input[type=search] {
  border: 0;
  height: 73px;
  font-size: 20px;
  padding: 0 40px 0 25px;
  width: 100%
}

.search button[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  line-height: 73px;
  font-size: 30px;
  background: 0 0
}

.search button i {
  background: linear-gradient(330deg, #1d92de 35%, #9952e0 75%, #9974dc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.search button[type=button] {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 73px;
  font-family: 'Open Sans';
  font-size: 20px;
  background: 0 0;
  border: 0;
  color: #ccc
}

nav .open-search {
  line-height: 70px;
  font-size: 20px;
  display: inline-block;
  padding: 0 15px;
  position: relative;
  top: 2px
}

nav .open-search i {
  background: linear-gradient(330deg, #1d92de 35%, #9952e0 75%, #9974dc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

nav.close {
  display: none
}

.intro {
  position: relative;
  z-index: 0
}

.intro .intro-fixed {
  position: fixed;
  width: 100%
}

.card,
.page {
  position: relative
}

.intro .intro-text {
  margin: 70px 0 0
}

.page {
  background-color: #fff;
  overflow: hidden;
  z-index: 2;
  padding: 0 0 70px;
}

.card.space {
  padding-top: 35px
}

.card .title {
  margin-bottom: 40px
}

.card .card-img-animation {
  display: table;
  position: relative;
  height: 390px;
  width: 390px;
  border-radius: 100%;
  border: 3px dotted rgba(7, 117, 188, .3);
  margin: 0 auto
}

.card .card-img {
  max-width: 246px;
  max-height: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -80px;
  margin-left: -128px;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-timing-function: cubic-bezier(.11, .11, .2, 1.42);
  -moz-transition-timing-function: cubic-bezier(.11, .11, .2, 1.42);
  -o-transition-timing-function: cubic-bezier(.11, .11, .2, 1.42);
  transition-timing-function: cubic-bezier(.11, .11, .2, 1.42);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden
}

.card-img-animation:hover .card-front,
.card-img-animation:hover img:first-child {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.card-img-animation:hover .card-back,
.card-img-animation:hover img:last-child {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0)
}

.card-img-animation .card-back,
.card-img-animation img:last-child {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg)
}

.card .item-one-dot {
  width: 10px;
  height: 10px;
  background-color: #0775bc;
  border-radius: 100%;
  position: absolute;
  top: 68px;
  left: 17px;
  border: 10px solid #fff;
  box-sizing: content-box
}

.card .item-dot {
  position: relative
}

.card .item-dot:after {
  content: "";
  border: 3px solid #efefef;
  width: 10px;
  height: 10px;
  box-sizing: content-box;
  border-radius: 100%;
  position: absolute;
  top: -3px;
  left: -3px
}

.card .item-three-dot,
.card .item-two-dot {
  width: 10px;
  height: 10px;
  background-color: #0775bc;
  border-radius: 100%;
  position: absolute
}

.card .item-two-dot {
  top: 180px;
  left: -17px;
  border: 10px solid #fff;
  box-sizing: content-box
}

.card .item-three-dot {
  bottom: 68px;
  left: 17px;
  border: 10px solid #fff;
  box-sizing: content-box
}

.card .item-five-dot,
.card .item-four-dot {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #0775bc;
  box-sizing: content-box;
  position: absolute
}

.card .item-four-dot {
  top: 68px;
  right: 17px;
  border: 10px solid #fff
}

.card .item-five-dot {
  top: 180px;
  right: -17px;
  border: 10px solid #fff
}

.card .item-six-dot {
  width: 10px;
  height: 10px;
  background-color: #0775bc;
  border-radius: 100%;
  position: absolute;
  bottom: 68px;
  right: 17px;
  border: 10px solid #fff;
  box-sizing: content-box
}

.card .card-item {
  width: 400px
}

.card .card-item.item-one {
  position: absolute;
  top: 20px;
  left: -180px;
  text-align: right
}

.card .card-item.item-two {
  position: absolute;
  top: 162px;
  left: -223px;
  text-align: right
}

.card .card-item.item-three {
  position: absolute;
  bottom: 28px;
  left: -180px;
  text-align: right
}

.card .card-item.item-four {
  position: absolute;
  top: 20px;
  right: -180px
}

.card .card-item.item-five {
  position: absolute;
  top: 162px;
  right: -223px
}

.card .card-item.item-six {
  position: absolute;
  bottom: 28px;
  right: -180px
}

.features,
.features .features-arrows {
  position: relative
}

.card .card-item figure img {
  width: 45px;
  display: inline-block;
  margin: 0 15px
}

.card .card-item figure figcaption {
  display: inline-block;
  font-size: 18px;
  font-weight: lighter;
  position: relative;
  top: 13px
}

.card .card-item figure figcaption span {
  display: block;
  font-weight: lighter;
  font-size: 18px;
  color: #000
}

.features .features-arrows:after,
.features .features-arrows:before {
  content: "\f107";
  font-size: 32px;
  color: #3d8add;
  width: 50px;
  height: 50px;
  background-color: #fff;
  left: 50%;
  margin-left: -25px;
  z-index: 10;
  border-radius: 100%;
  text-align: center;
  font-family: FontAwesome
}

.features {
  background: #fff
}

.career-moto,
.features:before {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#55bbfd, endColorstr=#1d92de);
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%)
}

.features .features-arrows:before {
  line-height: 73px;
  position: absolute;
  top: -90px
}

.features .features-arrows:after {
  line-height: 35px;
  position: absolute;
  bottom: -90px
}

.features:before {
  content: '';
  background-color: #3bcdd6;
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc));
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(0) skewY(-5deg);
  transform: translateY(0) skewY(-5deg)
}

.benefits .benefit.left:after,
.features ul li:before,
.tree:after {
  content: ""
}

.features-imgs {
  position: relative;
  margin: 50px 0 0
}

.features-laptop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  max-height: 310px
}

.features-phone {
  position: absolute;
  top: 50px;
  left: 400px;
  z-index: 5;
  max-height: 310px
}

.features-card {
  position: absolute;
  top: 220px;
  left: 240px;
  z-index: 5;
  max-height: 150px
}

.features .slogan,
.features .title {
  color: #fff
}

.features ul {
  margin: 30px 0 0
}

.features ul li:before {
  position: absolute;
  top: 7px;
  left: 0;
  background: url(../images/icons/checked.svg) no-repeat;
  background-size: 30px;
  width: 30px;
  height: 30px
}

.features ul li {
  position: relative;
  padding: 8px 0 8px 40px;
  font-size: 16px;
  display: block;
  color: #fff
}

.features ul li:last-of-type {
  border: 0
}

.benefits .benefit.left:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #eee
}

.benefits .benefit.left {
  padding-right: 100px
}

.benefits .benefit.right {
  padding-left: 20px;
  padding-right: 50px
}

.benefits .benefit img {
  height: 125px;
  margin: 0 0 10px
}

.benefits .benefit h3 {
  font-size: 24px;
  margin: 0 0 7px;
  font-weight: 700
}

.benefits .benefit p {
  font-weight: lighter;
  font-size: 16px
}

.benefits .benefit:hover .sep span {
  width: 30px
}

.benefits ul {
  margin: 0 0 20px
}

.benefits ul li {
  color: #333;
  font-size: 16px;
  margin: 0 0 5px
}

.benefits ul li:before {
  content: "• ";
  font-size: 20px;
  line-height: 1;
  margin-right: 7px;
  color: #0775bc
}

.sep {
  font-size: 0;
  margin: 0 0 10px
}

.sep span {
  width: 20px;
  height: 3px;
  display: inline-block;
  transition: all .4s
}

.sep span:nth-child(1) {
  background-color: red
}

.sep span:nth-child(2) {
  background-color: pink
}

.sep span:nth-child(3) {
  background-color: purple
}

.credit-gauge .credit-gauge-img {
  max-height: 370px;
  margin: 90px auto;
  display: block
}

.credit-gauge .section-icon img {
  height: 125px;
  margin: 0 0 10px
}

.credit-gauge h3 {
  margin: 0 0 7px
}

.credit-gauge:hover .sep span {
  width: 30px
}

.credit-gauge p {
  font-size: 16px;
  line-height: 1.5
}

.credit-gauge p:last-of-type {
  margin: 0
}

.credit-gauge ul {
  margin: 0 0 20px
}

.credit-gauge ul li {
  color: #333;
  font-size: 16px;
  margin: 0 0 5px
}

.credit-gauge ul li:before {
  content: "• ";
  font-size: 20px;
  line-height: 1;
  margin-right: 7px;
  color: #0775bc
}

.steps {
  position: relative;
  text-align: center
}

.steps.space {
  padding-top: 55px
}

.steps .step-icon {
  border-radius: 100%;
  padding: 20px;
  display: block;
  width: 155px;
  height: 125px;
  margin: 0 auto
}

.steps .step-icon img {
  width: 130px;
  margin: 6px 0
}

.steps .step-num {
  color: rgba(179, 157, 219, .1);
  font-size: 70px;
  font-weight: 700;
  position: absolute;
  top: 20px;
  right: 60px
}

.steps h3 {
  font-size: 18px;
  font-weight: 700
}

.steps p {
  margin: 0
}

.tree {
  margin: 30px 0 0;
  padding: 100px 0;
  position: relative
}

.tree:after {
  position: absolute;
  top: 0;
  right: 50%;
  width: 2px;
  height: 100%;
  margin-right: -8px;
  background-color: #b39ddb;
  box-shadow: 0 0 50px 10px rgba(179, 157, 219, .25);
  border-radius: 100%
}

.tree .tree-left-side:after,
.tree .tree-left-side:before {
  content: "";
  position: absolute;
  top: 50%
}

.tree .tree-box img {
  height: 70px;
  margin: 0 0 15px
}

.tree .tree-box p {
  margin: 0
}

.tree .tree-box h3 {
  font-size: 24px;
  line-height: 1.5;
  color: #b39ddb
}

.tree .tree-left-side {
  text-align: right;
  padding: 0 130px 0 20px
}

.tree .tree-left-side:before {
  width: 100px;
  height: 2px;
  margin-top: 20px;
  right: 0;
  background-color: #b39ddb
}

.tree .tree-left-side:after {
  margin-top: 16px;
  right: 100px;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100%;
  background-color: #fff;
  border: 2px solid #b39ddb
}

.tree .tree-right-side {
  text-align: left;
  padding: 0 20px 0 130px;
  position: relative
}

.tree .tree-right-side:after,
.tree .tree-right-side:before {
  content: "";
  position: absolute;
  top: 50%
}

.tree .tree-right-side:before {
  width: 100px;
  height: 2px;
  margin-top: 18px;
  left: 0;
  background-color: #b39ddb
}

.tree .tree-right-side:after {
  margin-top: 14px;
  left: 100px;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100%;
  background-color: #fff;
  border: 2px solid #b39ddb
}

.career-moto {
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

.about .about-text-second:after,
.refresh-captcha {
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#55bbfd, endColorstr=#1d92de)
}

.career-moto p {
  text-align: center;
  color: #fff;
  font-size: 26px;
  line-height: 30px;
  font-weight: lighter;
  font-style: italic
}

.about {
  position: relative
}

.intro.about .item {
  padding: 80px 0
}

.intro.about h1 {
  font-size: 34px;
  line-height: 46px;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  margin: 0 0 50px;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25)
}

.intro.about .about-intro-value {
  font-size: 52px;
  line-height: 62px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25)
}

.intro.about .about-intro-label {
  font-size: 22px;
  line-height: 22px;
  font-weight: lighter;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25)
}

.about .about-text-first {
  min-height: 250px;
  margin: 0 0 50px;
  text-align: left
}

.about .about-text-first h2,
.about .about-text-second h2 {
  margin: 0 0 20px;
  font-size: 30px;
  line-height: 38px;
  color: #000;
  font-weight: lighter
}

.about .about-text-second {
  position: relative;
  min-height: 250px;
  margin: 0 0 100px;
  text-align: right;
  padding: 30px 0
}

.about .about-text-second h2,
.about .about-text-second p {
  color: #fff
}

.about .about-text-second:after {
  content: '';
  background-color: #3bcdd6;
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc));
  width: 100%;
  min-height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(0) skewY(-5deg);
  transform: translateY(0) skewY(-5deg)
}

.short-contacts ul {
  margin: 15px 0 0;
  display: inline-block;
  width: 100%
}

.short-contacts .item {
  border: 1px solid #ddd;
  padding: 35px;
  text-align: center
}

.short-contacts .item .icon img {
  width: 70px;
  margin: 0 0 15px
}

.short-contacts .item h3 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 700
}

.short-contacts .item p {
  line-height: 28px
}

.blog .list .post {
  border-bottom: 1px solid #ddd;
  padding: 60px 0
}

.blog .list .post:first-of-type {
  padding-top: 0
}

.blog .list .post:last-of-type {
  border-bottom: 0
}

.blog .list .post .post-img {
  margin: 0 0 20px;
  overflow: hidden
}

.blog .list .post .post-img figure {
  position: relative;
  height: 100%;
  overflow: hidden
}

.blog .list .post .post-img figure figcaption {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(7, 117, 188, .35);
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s
}

.blog .list .post .post-img:hover figure figcaption {
  top: 0
}

.blog .list .post .post-img figure figcaption ul {
  position: absolute;
  top: 50%;
  margin-top: -19px;
  display: block;
  text-align: center;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s
}

.blog .list .post .post-img:hover figure figcaption ul {
  opacity: 1
}

.blog .list .post .post-img figure figcaption ul li {
  background-color: #0775bc;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 44px;
  border-radius: 100%
}

.blog .list .post .post-img figure figcaption ul li i {
  font-size: 18px;
  color: #fff
}

.blog .list .post .post-title {
  font-size: 20px;
  line-height: 1.5
}

.blog .list .post .post-title a {
  color: #333
}

.blog .list .post .post-title a:hover {
  color: #0775bc;
  text-decoration: none
}

.blog .list .post .post-content-container {
  position: relative;
  padding: 0 0 0 100px
}

.blog .list .post .post-content-container p {
  margin-bottom: 0
}

.blog .list .post .post-content-container .date-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 67px;
  height: 60px;
  padding: 5px;
  background-color: #0775bc;
  text-align: center;
  color: #fff
}

.blog .list .post .post-content-container .date-box span {
  font-size: 30px;
  line-height: 30px;
  display: block;
  font-weight: 700
}

.blog .list .post .post-meta {
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin: 5px 0 15px !important;
  color: #b5b5b5
}

.blog .list .post .post-meta a {
  color: #0775bc
}

.blog .list .post .post-meta a:hover {
  text-decoration: underline
}

.blog .list .post .post-meta .sep {
  color: #333;
  padding: 0 5px;
  display: inline-block;
  font-size: 11px;
  position: relative;
  top: -1px
}

.blog .list .post .post-meta .views img {
  width: 14px;
  position: relative;
  top: -1px;
  margin-right: 4px
}

.blog .loading_logo {
  margin: 15px auto 0;
  display: block
}

.blog aside {
  padding: 0 0 0 50px;
  position: relative
}

.blog aside .widget {
  margin: 0 0 35px
}

.blog aside .widget h3 {
  font-size: 18px;
  line-height: 1.4;
  margin: 0 0 10px;
  border-left: 3px solid #0775bc;
  padding: 0 0 0 14px
}

.blog aside .widget.widget-categories ul li {
  display: block
}

.blog aside .widget.widget-categories ul li a {
  display: block;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  color: #333
}

.blog aside .widget.widget-categories ul li a:hover {
  color: #0775bc;
  text-decoration: none
}

.blog aside .widget.widget-categories ul li a:before {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 12px;
  line-height: 1;
  margin-right: 7px;
  position: relative;
  top: -1px
}

.blog aside .widget.widget-categories ul li:last-of-type a {
  border: 0
}

.blog aside .widget .widget-tabs-nav {
  font-size: 0;
  width: 100%;
  margin: 0 0 15px
}

.blog aside .widget .widget-tabs-nav li {
  display: inline-block;
  width: 50%
}

.blog aside .widget .widget-tabs-nav li.active a {
  border-top: 3px solid #0775bc;
  background-color: transparent;
  color: #0775bc
}

.blog aside .widget .widget-tabs-nav li a {
  font-size: 14px;
  text-align: center;
  display: block;
  background-color: #0775bc;
  color: #fff;
  padding: 9px 0;
  border-top: 3px solid #0775bc
}

.blog aside .widget .widget-list .post-title {
  display: block;
  line-height: 1.5;
  color: #333
}

.blog aside .widget .widget-list .post-title:hover {
  text-decoration: none
}

.blog aside .widget .widget-list .post-img {
  float: left;
  margin: 0 15px 0 0;
  width: 66px;
  height: 66px
}

.blog aside .widget .widget-list .post-meta {
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin: 0;
  color: #b5b5b5
}

.blog aside .widget .widget-list .post-meta a {
  color: #0775bc
}

.blog aside .widget .widget-list .post-meta a:hover {
  text-decoration: underline
}

.blog aside .widget .widget-list .post-meta .sep {
  color: #333;
  padding: 0 5px;
  display: inline-block;
  font-size: 11px;
  position: relative;
  top: -1px
}

.blog aside .widget .widget-list li {
  padding: 15px 0;
  border-bottom: 1px solid #ddd
}

.blog aside .widget .widget-list li:last-of-type {
  border: 0
}

.blog aside .widget .widget-list li figure > a:hover {
  color: #0775bc
}

.related h2 {
  margin: 20px 0 15px;
  border-top: 1px solid #e7e6e6;
  border-bottom: 1px solid #e7e6e6;
  font-size: 16px;
  line-height: 1.5;
  padding: 5px 0
}

.related ul li figure figcaption {
  margin: 10px 0 0
}

.related ul li figure figcaption h3 a {
  font-size: 14px;
  line-height: 20px;
  min-height: 42px;
  display: block;
  color: #333
}

.related ul li figure figcaption h3 a:hover {
  color: #0775bc;
  text-decoration: none
}

.related ul li figure .hover-effect {
  overflow: hidden
}

.related ul li figure .post-meta {
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin: 5px 0 15px !important;
  color: #b5b5b5
}

.related ul li figure .post-meta a {
  color: #0775bc
}

.related ul li figure .post-meta a:hover {
  text-decoration: underline
}

.related ul li figure .post-meta .sep {
  color: #333;
  padding: 0 5px;
  display: inline-block;
  font-size: 11px;
  position: relative;
  top: -1px
}

.related ul li figure img {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}

.related ul li:hover figure img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15)
}

.news .list .post {
  border-bottom: 1px solid #ddd;
  padding: 25px 0
}

.news .list .post:last-of-type {
  border-bottom: 0
}

.news .list .post .post-img figure {
  position: relative;
  overflow: hidden
}

.news .list .post .post-img figure figcaption {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(7, 117, 188, .35);
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s
}

.news .list .post .post-img:hover figure figcaption {
  top: 0
}

.news .list .post .post-img figure figcaption ul {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  display: block;
  text-align: center;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s
}

.news .list .post .post-img:hover figure figcaption ul {
  opacity: 1
}

.news .list .post .post-img figure figcaption ul li {
  background-color: #0775bc;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 44px;
  border-radius: 100%
}

.news .list .post .post-img figure figcaption ul li i {
  font-size: 18px;
  color: #fff
}

.news .list .post .post-title {
  font-size: 20px;
  line-height: 1.5
}

.news .list .post .post-title a {
  color: #333
}

.news .list .post .post-title a:hover {
  color: #0775bc;
  text-decoration: none
}

.article .article-meta a:hover,
.news .list .post .post-meta a:hover {
  text-decoration: underline
}

.news .list .post .post-content-container {
  position: relative
}

.news .list .post .post-content-container p {
  margin-bottom: 0
}

.news .list .post .date-box {
  width: 67px;
  height: 60px;
  padding: 5px;
  background-color: #0775bc;
  text-align: center;
  color: #fff
}

.news .list .post .date-box span {
  font-size: 30px;
  line-height: 30px;
  display: block;
  font-weight: 700
}

.news .list .post .post-meta {
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin: 5px 0 15px !important;
  color: #b5b5b5
}

.news .list .post .post-meta a {
  color: #0775bc
}

.news .list .post .post-meta .sep {
  color: #333;
  padding: 0 5px;
  display: inline-block;
  font-size: 11px;
  position: relative;
  top: -1px
}

.news .list .post .post-meta .views img {
  width: 14px;
  position: relative;
  top: -1px;
  margin-right: 4px
}

.blog .article .article-img figure {
  float: none;
  margin: 0 0 10px
}

.blog .article .article-img figure img {
  max-width: 100%
}

.article .article-img figure {
  float: left;
  margin: 0 30px 20px 0
}

.article .article-img figure img {
  max-width: 320px
}

.article .article-title {
  font-size: 30px;
  line-height: 36px
}

.article .article-content {
  margin: 20px 0
}

.article .article-content ul {
  margin: 0 0 20px
}

.article .article-content ul li:before {
  content: "• ";
  font-size: 20px;
  line-height: 1;
  margin-right: 7px;
  color: #0775bc
}

.map ul li a:before,
aside.sidebar ul li a:before {
  content: "\f105";
  font-family: FontAwesome
}

.article .article-meta {
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin: 5px 0 15px !important;
  color: #b5b5b5
}

.article .article-meta a {
  color: #0775bc
}

.article .article-meta .sep {
  color: #333;
  padding: 0 5px;
  display: inline-block;
  font-size: 11px;
  position: relative;
  top: -1px
}

.map ul li,
aside.sidebar ul li {
  display: block
}

.article .article-meta .views img {
  width: 14px;
  position: relative;
  top: -1px;
  margin-right: 4px
}

.article .article-navigation {
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  border-top: 1px solid #e7e6e6;
  border-bottom: 1px solid #e7e6e6;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase
}

.article .article-navigation a .fa {
  font-size: 15px
}

.article .article-navigation a:first-of-type {
  margin-right: 10px
}

.article .article-navigation a:first-of-type .fa {
  margin-right: 5px;
  position: relative;
  top: 1px
}

.article .article-navigation a:last-of-type .fa {
  margin-left: 5px;
  position: relative;
  top: 1px
}

.article .article-navigation a:last-of-type {
  margin-left: 10px
}

.article .article-navigation a:hover {
  color: #0775bc
}

aside.sidebar {
  padding: 20px 20px 15px;
  box-shadow: 0 0 53px -25px #8b78dc;
  border-left: 5px solid #b39ddb;
  border-radius: 5px;
  margin-right: 15px
}

aside.sidebar ul {
  margin: 0
}

aside.sidebar ul li:last-of-type a {
  border-bottom: 0
}

aside.sidebar ul li a {
  padding: 4px 6px 4px 20px;
  font-size: 14px;
  line-height: 30px;
  display: block;
  border-bottom: solid 1px #e1e1e1;
  position: relative;
  color: #333
}

aside.sidebar ul li a:before {
  position: absolute;
  left: 7px
}

aside.sidebar ul li a:hover,
aside.sidebar ul li.active a {
  color: #b39ddb;
  text-decoration: none
}

.text h1 {
  font-size: 30px;
  line-height: 36px
}

.map ul {
  margin: 20px 0
}

.map ul li {
  margin: 3px 0
}

.map ul li a {
  display: inline-block;
  position: relative;
  padding: 0 0 0 15px;
  color: #333
}

.map ul li a:hover {
  color: #0775BC;
  text-decoration: none
}

.map ul li a:before {
  position: absolute;
  left: 0
}

.contacts .contact-intro {
  //background: url(../imgs/contacts.jpg) center center no-repeat;
  background-size: cover;
  min-height: 450px;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: inset 0 -1px 6px 0 rgba(0, 0, 0, .2);
  -moz-box-shadow: inset 0 -1px 6px 0 rgba(0, 0, 0, .2);
  box-shadow: inset 0 -1px 6px 0 rgba(0, 0, 0, .2)
}

.contacts .contact-intro:after {
  content: "";
  background-color: rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%
}

.contacts .contact-intro .container {
  position: relative;
  z-index: 50
}

.contacts .contact-intro .info-box {
  background-color: #fff;
  padding: 25px 20px;
  margin: 30px 0;
  border-radius: 2px
}

.contacts .contact-intro .info-box h3 {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 0 25px;
  text-align: center
}

.contacts .contact-intro .info-box p {
  position: relative;
  padding: 0 0 12px 50px;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid #ddd
}

.contacts .contact-intro .info-box p .value a {
  display: block;
  font-weight: 700;
  font-size: 28px;
  margin: 0 0 10px;
  color: #00aacd
}

.contacts .contact-intro .info-box p .hint a {
  color: #a7a7a7
}

.contacts .contact-intro .info-box i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 40px;
  color: #00aacd
}

.contacts .contact-intro .info-box i.fa-mobile {
  left: 10px
}

.contacts .contact-intro .info-box .email a {
  text-align: center;
  color: #00aacd;
  display: block;
  font-size: 16px
}

.contacts .contact-intro h1 {
  text-align: center;
  font-size: 28px;
  line-height: 1.5;
  color: #fff;
  margin-top: 40px
}

.contacts .contact-intro .breadcrumb-intro {
  font-size: 0;
  margin: 0 auto;
  display: table
}

.contacts .contact-intro .breadcrumb-intro li {
  display: inline-block;
  margin: 0 13px;
  position: relative
}

.contacts .contact-intro .breadcrumb-intro li:after {
  content: "/";
  position: absolute;
  top: 0;
  right: -17px;
  color: #fff;
  font-size: 16px
}

.contacts .contact-intro .breadcrumb-intro li:last-of-type:after {
  display: none
}

.contacts .contact-intro .breadcrumb-intro li a {
  display: block;
  font-size: 14px;
  color: #fff
}

.contacts .contact-intro .social-intro ul {
  margin: 20px auto;
  display: table
}

.contacts .contact-intro .social-intro ul li {
  display: inline-block;
  margin: 0 3px
}

.contacts .contact-intro .social-intro ul li a {
  display: block;
  border: 1px solid #fff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  border-radius: 2px
}

.contacts .contact-intro .social-intro ul li a:hover {
  background-color: #fff;
  color: #333e48
}

.contacts .contact-form {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 2px
}

.contacts .contact-form h1,
.contacts .contact-form h3 {
  margin: 0 0 15px;
  font-size: 26px;
  line-height: 1.5
}

.contacts .contact-form form textarea {
  min-height: 164px
}

.contacts .contact-form label {
  //display: block;
  font-size: 16px;
  margin: 0 0 6px
}

.contacts .contact-form input[type=text] {
  padding: 0 10px 0 40px
}

.contacts .contact-form input[type=text],
.contacts .contact-form textarea {
  width: 100%
}

.contacts .contact-form .btn-theme {
  margin-top: 26px;
  width: 100%
}

.contacts .contact-form .form-group {
  position: relative
}

.contacts .contact-form .form-group i {
  position: absolute;
  top: 36px;
  left: 13px;
  font-size: 18px;
  color: #c3c3c3
}

.contacts .contact-form .form-group .fa-long-arrow-right {
  left: 28px
}

#captcha {
  border: 1px solid #ddd;
  display: inline-block
}

.refresh-captcha {
  border: 1px solid #84b9e1;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  padding: 0 6px;
  position: relative;
  top: 2px;
  margin-right: 8px;
  font-size: 13px;
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

.refresh-captcha i {
  margin-right: 3px;
  font-size: 12px !important;
  color: #fff !important;
  position: relative !important;
  left: inherit !important;
  top: 0 !important
}

.refresh-captcha:active,
.refresh-captcha:focus,
.refresh-captcha:hover,
.refresh-captcha:visited {
  text-decoration: none;
  color: #fff;
  opacity: .8
}

.contacts-info {
  padding: 30px
}

.contacts-info h3 {
  text-align: center;
  font-size: 26px;
  line-height: 1.5
}

.contacts-info ul {
  text-align: center;
  margin: 15px 0
}

.contacts-info ul li i {
  margin-right: 6px
}

.contacts-info ul.opening-times {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: inline-block;
  width: 100%
}

.contacts-info ul.opening-times li div {
  text-transform: uppercase;
  margin: 0 0 15px;
  font-size: 18px
}

.contacts-info ul.opening-times li span {
  display: block;
  margin: 0 0 25px;
  font-style: italic;
  color: #bbb;
  font-size: 14px
}

.contacts-info ul.opening-times li:last-of-type span {
  margin-bottom: 0
}

.contacts-info ul.company-info {
  padding: 15px 0;
  border-top: 1px solid #ddd;
  display: inline-block;
  width: 100%
}

.contacts-info ul.company-info li div {
  text-transform: uppercase;
  margin: 0 0 5px;
  line-height: 1.4;
  display: block;
  font-style: italic;
  color: #bbb;
  font-size: 14px
}

.contacts-info ul.company-info li h4 {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 5px
}

.contacts-info ul.opening-times {
  padding: 15px 0;
  margin-bottom: 0
}

.contacts-info ul.info li {
  line-height: 1
}

.contacts-info ul.info li div {
  text-transform: uppercase;
  margin: 0 0 15px;
  font-size: 18px
}

.contacts-info ul.info li span a {
  display: block;
  font-style: italic;
  color: #bbb;
  font-size: 14px
}

.contacts-info ul.info li:last-of-type span {
  margin-bottom: 0
}

#map {
  min-height: 400px;
  border-bottom: 1px solid #ddd
}

.mapHolder {
  height: 400px;
}

.financial-glossary .glossary-search {
  margin: 20px 0 50px
}

.financial-glossary .glossary-search form {
  position: relative
}

.financial-glossary .glossary-search form input[type=search] {
  width: 100%;
  height: 40px;
  border: 1px solid #084f70;
  font-size: 15px;
  padding: 0 50px 0 15px
}

.financial-glossary .glossary-search form button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  height: 40px;
  width: 40px;
  line-height: 38px;
  font-size: 15px;
  color: #0775BC
}

.financial-glossary .glossary-search form button:before {
  content: "";
  height: 25px;
  width: 1px;
  position: absolute;
  left: 0;
  top: calc(50% - 13.5px);
  display: block;
  background-color: #084f70
}

.financial-glossary .glossary-filter {
  border-bottom: 1px solid #0775BC;
  margin: 0 0 30px
}

.financial-glossary .glossary-filter ul {
  margin: auto;
  display: table
}

.financial-glossary .glossary-filter ul li {
  display: inline-block;
  position: relative
}

.financial-glossary .glossary-filter ul li.inactive a {
  color: #ddd
}

.financial-glossary .glossary-filter ul li.active a:after {
  content: "\f0d7";
  color: #0775BC;
  position: absolute;
  bottom: -14px;
  left: calc(50% - 7px);
  display: inline-block;
  font: normal normal normal 24px/1 FontAwesome
}

.financial-glossary .glossary-filter ul li a:hover,
.financial-glossary .glossary-filter ul li a.active {
  background-color: #0775BC;
  color: #fff
}

.financial-glossary .accordion-toggle:hover,
.search_res {
  color: #0775BC
}

//.financial-glossary .glossary-filter ul li:first-of-type a {
//  width: 70px
//}

.financial-glossary .glossary-filter ul li a {
  width: 35px;
  height: 35px;
  //line-height: 35px;
  padding-left: 12px;
  display: block;
  font-size: 15px;
  text-align: center
}

.financial-glossary .accordion-toggle {
  font-size: 15px
}

.financial-glossary .accordion-toggle .fa {
  position: relative;
  top: 2px;
  font-size: 10px;
  padding: 5px 7px;
  background-color: #0775BC;
  color: #fff;
  font-weight: 400;
  margin-right: 10px
}

.financial-glossary .panel-heading.active .accordion-toggle,
.panel-heading.active {
  color: #0775BC
}

.financial-glossary .panel-body {
  color: #333;
  padding-left: 20px
}

.financial-glossary .glossary-list .panel {
  border: 0;
  box-shadow: none;
  margin-bottom: 8px
}

.financial-glossary .glossary-list .panel-default > .panel-heading {
  background-color: transparent;
  border: 0;
  padding: 0
}

.financial-glossary .glossary-list .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: 0;
  padding: 10px 0 15px 32px;
  font-size: 15px
}

.search-content .tab-content {
  margin: 30px 0
}

.search-content .blog .list .post {
  padding: 15px
}

.search-content .blog .list .post:last-of-type {
  border-bottom: 1px solid #ddd
}

.search-content .blog .list .post .post-title {
  min-height: 60px
}

.search-content .search-text h4 {
  margin: 0 0 10px
}

.search-content .search-text h4 a:hover {
  text-decoration: none
}

.search-content .search-text p {
  margin-bottom: 10px
}

.search-content .search-text hr {
  margin: 20px 0
}

.careers .positions {
  background-color: #F5F8FF;
  padding-bottom: 130px;
  margin-bottom: -100px
}

.careers .positions .position {
  position: relative;
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .15);
  margin: 0 0 30px
}

.careers .positions .position:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 5px;
  position: absolute;
  top: -4px;
  left: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#55bbfd, endColorstr=#1d92de);
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

.careers .positions .position h4 {
  font-size: 22px;
  line-height: 1.5
}

.careers .positions .position h4 a {
  color: #333
}

.careers .positions .position h4 a:hover {
  color: #0775BC;
  text-decoration: none
}

.careers .positions ul {
  display: inline-block;
  width: 100%;
  margin: 25px 0 0
}

.careers .positions .position .position-label {
  display: inline-block;
  border-radius: 3px;
  margin: 0 0 10px;
  font-size: 13px;
  background: linear-gradient(330deg, #1d92de 35%, #9952e0 75%, #9974dc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.careers .positions .position p {
  margin-bottom: 0
}

.careers .positions .position a.read-more {
  position: relative;
  display: block
}

.careers .positions .position a.read-more:before {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  right: -9px;
  bottom: -16px;
  z-index: 1;
  color: #fff;
  font-size: 30px
}

.careers .positions .position a.read-more:after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 70px solid #b39ddb;
  border-left: 70px solid transparent;
  position: absolute;
  right: -25px;
  bottom: -25px
}

footer,
footer .subscribe form,
footer h4 {
  position: relative
}

.careers .apply .apply-title {
  font-size: 22px;
  line-height: 1.5
}

.careers .apply .apply-location i {
  margin-right: 6px
}

.careers .apply .apply-attachment-cv {
  padding: 20px 0;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-top: 3px solid #b39ddb;
  margin: 20px 0
}

.careers .apply .apply-attachment-cv input[type=file] {
  position: relative;
  top: 7px;
  width: 100%
}

.careers .apply .apply-attachment-cv p {
  margin: 0;
  line-height: 36px
}

.careers .apply ul li::before {
  content: "• ";
  font-size: 20px;
  line-height: 1;
  margin-right: 7px;
  color: #0775bc
}

footer {
  background-color: #0775bc;
  padding: 20px 0;
  margin: 0;
  z-index: 10
}

footer h4 {
  font-size: 16px;
  margin: 0 0 15px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase
}

footer .footer-nav li a {
  color: #fff;
  padding: 0 0 0 8px
}

footer .footer-nav li a:hover {
  color: rgba(255, 255, 255, .7);
  text-decoration: none
}

footer .footer-nav li:before {
  content: "\f105";
  font-family: FontAwesome;
  color: #fff
}

.wave,
.waves {
  position: absolute
}

footer .subscribe form input[type=email] {
  width: 100%;
  border-radius: 30px;
  padding: 0 93px 0 17px;
  height: 40px;
  margin-bottom: 6px
}

footer .subscribe form input[type=email]:focus {
  border-color: #fff
}

footer .subscribe form input[type=submit] {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 36px;
  border-radius: 30px;
  background-color: #0775bc;
  border: 0;
  padding: 0 15px;
  color: #fff;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s
}

footer .subscribe form input[type=submit]:hover {
  background-color: #9233bd
}

footer .certificates {
  width: 100%;
  display: inline-block;
  margin: 7px 0 5px
}

footer .certificates li {
  display: inline-block;
  background: url(../images/certificate-bg.png) center center no-repeat;
  background-size: 170px 46px;
  width: 170px;
  height: 46px;
  color: #c5d0d7;
  font-size: 11px;
  line-height: 14px;
  padding-top: 5px;
  text-align: center;
  float: left;
  margin-right: 20px
}

footer .certificates li:last-of-type {
  margin-right: 0
}

footer .certificates li a {
  display: block;
  font-size: 14px;
  line-height: 30px;
  font-weight: 700;
  color: #fff
}

footer .certificates li a:active,
footer .certificates li a:focus,
footer .certificates li a:hover,
footer .certificates li a:visited {
  color: #c5d0d7
}

footer .social li {
  display: inline-block;
  margin: 0 3px
}

footer .social li a {
  background-color: #fff;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  display: block;
  color: #0775bc;
  border: 2px solid #fff
}

footer .social li a:hover {
  border: 2px solid #fff;
  color: #fff;
  background: 0 0
}

footer .copyright {
  color: #fff;
  text-align: center;
  margin: 25px 0 0;
  border-top: 1px solid rgba(255, 255, 255, .16);
  padding: 10px 0 0
}

.waves {
  top: -130px;
  width: 100%;
  height: 130px
}

.wave {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1
}

.wave + .wave {
  z-index: 2
}

.scrollTop-footer {
  position: fixed;
  font-size: 20px;
  line-height: 35px;
  display: block;
  text-align: center;
  right: 20px;
  color: #fff;
  cursor: pointer;
  z-index: 100;
  height: 40px;
  width: 40px;
  border: 1px solid #b39ddb;
  bottom: 73px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  opacity: 0;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s
}

.scrollTop-footer svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #0775BC;
  border-color: #0775BC
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #fff;
  background-color: #0775BC;
  border-color: #0775BC
}

.pagination > li > a,
.pagination > li > span {
  color: #0775BC;
  border-color: #e9e9e9
}

.form-control:focus {
  border: 1px solid #adadad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  outline: 0 !important
}

.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 80px;
  margin-left: -12px;
  width: 24px;
  height: 24px
}

.chevron {
  position: absolute;
  width: 28px;
  height: 3px;
  opacity: 0;
  transform: scale3d(.5, .5, .5);
  animation: move 3s ease-out infinite
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite
}

.chevron:after,
.chevron:before {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #333
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg)
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg)
}

@keyframes move {
  25% {
    opacity: 1
  }
  33% {
    opacity: 1;
    transform: translateY(30px)
  }
  67% {
    opacity: 1;
    transform: translateY(40px)
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(.5, .5, .5)
  }
}

.scroll-text {
  display: block;
  margin-top: 70px;
  margin-left: -30px;
  font-size: 12px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  animation: pulse 2s linear alternate infinite
}

#rates .hint-rates,
#rates table,
.btngradient,
.btngradient:hover,
.download-rates {
  color: #fff
}

@keyframes pulse {
  to {
    opacity: 1
  }
}

#top-progress-bar {
  z-index: 150
}

.btngradient {
  display: inline-block;
  padding: 4px 15px;
  border-radius: 3px;
  text-transform: uppercase;
  border: 1px solid #84b9e1;
  margin: 80px 0 0;
  width: 150px;
  font-size: 0
}

.btngradient.btn1 {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#62a7d5, endColorstr=#0775bc);
  background-image: -moz-linear-gradient(left, #62a7d5 16%, #0775bc 51%, #0775bc 100%);
  background-image: linear-gradient(left, #62a7d5 16%, #0775bc 51%, #0775bc 100%);
  background-image: -webkit-linear-gradient(left, #62a7d5 16%, #0775bc 51%, #0775bc 100%);
  background-image: -o-linear-gradient(left, #62a7d5 16%, #0775bc 51%, #0775bc 100%);
  background-image: -ms-linear-gradient(left, #62a7d5 16%, #0775bc 51%, #0775bc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(20%, #62a7d5), color-stop(40%, #0775bc), color-stop(80%, #0775bc))
}

.btngradient.btn2,
.btngradient.btn3 {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#66b0e0, endColorstr=#0775bc)
}

.btngradient.btn2 {
  background-image: -moz-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -webkit-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -o-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -ms-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(16%, #66b0e0), color-stop(51%, #0775bc), color-stop(100%, #0775bc))
}

.btngradient.btn3 {
  background-image: -moz-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -webkit-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -o-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -ms-linear-gradient(left, #66b0e0 16%, #0775bc 51%, #0775bc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(5%, #66b0e0), color-stop(37%, #0775bc), color-stop(95%, #0775bc))
}

#rates .modal-content:after,
.btngradient.btn4 {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#55bbfd, endColorstr=#1d92de)
}

.btngradient.btn4 {
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc))
}

.modal {
  overflow-y: auto
}

.modal-open {
  overflow: auto
}

.modal-open[style] {
  padding-right: 0 !important
}

#rates .modal-content {
  background: 0 0;
  box-shadow: none;
  border: 0
}

#rates .modal-content:after {
  content: "";
  background-image: -moz-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -o-linear-gradient(left, #55bbfd 0, #1d92de 35%, #9974dc 100%);
  background-image: -ms-linear-gradient(left, #55bbfd 6%, #1d92de 35%, #9974dc 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0, #55bbfd), color-stop(35%, #1d92de), color-stop(100%, #9974dc));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(0) skewY(-5deg);
  transform: translateY(0) skewY(-5deg);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .4);
  box-shadow: 0 3px 9px rgba(0, 0, 0, .4);
  z-index: -1
}

#rates .modal-header {
  border-bottom: 0
}

#rates .modal-header h4 {
  font-size: 17px;
  text-align: center;
  font-weight: 700;
  color: #fff
}

#rates table tbody tr td:nth-child(2),
.download-rates {
  text-align: right
}

#rates .modal-header h4 span {
  display: block;
  font-weight: 400;
  font-size: 14px
}

#rates .modal-footer {
  border-top: 0
}

#rates .modal-header .close {
  margin-top: -32px;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px
}

#rates .modal-dialog {
  margin: 50px auto
}

.rate-char {
  position: fixed;
  left: 20px;
  bottom: 20px;
  max-height: 290px
}

.download-rates i {
  display: block;
  font-size: 44px
}

.download-rates {
  display: block
}

.alert-icon > .fa,
.error-404 {
  text-align: center
}

#CookiePolicy p,
.CookiePolicyClose {
  font-size: 13px;
  display: inline-block
}

.download-rates:active,
.download-rates:focus,
.download-rates:hover {
  color: #e2e2e2;
  text-decoration: none
}

#CookiePolicy a,
#CookiePolicy a:hover,
.CookiePolicyClose:hover {
  text-decoration: underline
}

#CookiePolicy {
  position: fixed;
  z-index: 10;
  top: 0;
  background: #0775BC;
  border-radius: 0;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  font-size: 13px;
  line-height: 38px
}

.error-404 .error-404-left,
.error-404 .error-404-right span {
  background: linear-gradient(330deg, #1d92de 35%, #9952e0 75%, #9974dc 100%);
  -webkit-text-fill-color: transparent
}

.CookiePolicyClose {
  cursor: pointer;
  float: right
}

.CookiePolicyClose .fa {
  margin-left: 2px
}

#CookiePolicy a {
  color: #fff
}

#CookiePolicy a:hover {
  color: #e6e6e6
}

#CookiePolicy p {
  margin-bottom: 0;
  color: #fff
}

.text table {
  border: 1px solid #333
}

.text table tbody tr td {
  padding: 2px 6px;
  border: 1px solid #333
}

.text table tbody tr td p {
  margin: 0
}

.error-404 .error-404-left {
  font-size: 32px;
  line-height: 1.5;
  padding: 150px 0;
  display: block;
  -webkit-background-clip: text
}

.error-404 .error-404-right {
  position: relative;
  font-size: 140px;
  font-weight: 400;
  line-height: 1.5;
  padding: 70px 0;
  display: block
}

.error-404 .error-404-right:before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: #b39ddb;
  position: absolute;
  left: 0;
  top: 0
}

.error-404 .error-404-right span {
  -webkit-background-clip: text
}

.error-404 .error-404-right em {
  font-size: 30px;
  line-height: 1.5;
  background: linear-gradient(330deg, #1d92de 35%, #9952e0 75%, #9974dc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.alert.dark.alert-danger {
  color: #fff;
  background-color: #f30052;
  border-color: #f30052
}

.alert-icon > .fa {
  margin-right: 6px;
  font-size: 16px
}

.alert .close {
  float: right;
  font-size: 21px;
  font-weight: lighter;
  line-height: 1.3;
  text-shadow: none;
  color: #fff;
  opacity: .6;
  font-family: 'Open Sans'
}

.contacts .contact-form form label[for=contact-terms],
footer .subscribe label[for=newsletter-terms] {
  display: inline;
  line-height: 12px;
  font-size: 14px
}

.alert .close:hover {
  opacity: 1
}

#allErrors {
  z-index: 100;
  position: relative
}

footer .subscribe label[for=newsletter-terms] {
  color: #fff
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: #e9e8e6 !important;
  border-color: #e9e8e6 !important;
  background-color: #fff !important;
  background-image: none !important
}

.promotion .promo-tabs {
  margin-bottom: 15px;
  border-bottom: 1px solid #0775bc;
  padding-left: 20px;
}

.promotion .promo-tabs {
  font-size: 0;
}

.promotion .promo-tabs li {
  display: inline-block;
}

.promotion .promo-tabs li.active a {
  color: #0775bc;
  text-decoration: none;
  border-top: 4px solid #0775bc;
  border-right: 2px solid #0775bc;
  border-left: 2px solid #0775bc;
}

.promotion .promo-tabs li.active a:before {
  content: "";
  background-color: #fff;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%
}

.promotion .promo-tabs li a {
  position: relative;
  font-size: 14px;
  padding: 5px 13px;
  transition: none;
  display: block;
  width: 120px;
  text-align: center;
  color: #84b9e1;
  border-radius: 5px 5px 0 0;
  color: #333;
  border-top: 3px solid transparent;
}

.promotion .promo-tabs li a:hover {
  text-decoration: none;
}

.promo-end-time {
  background-color: #0775bc;
  margin: 20px auto;
  display: table;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
}

.promo-end-time i {
  margin-right: 5px;
}

.promotions-list .promotion-item {
  border-bottom: 1px solid #ddd;
  padding: 25px 0;
}

.promotions-list .promotion-item:last-of-type {
  border-bottom: 0;
}

.promotions-list .promotion-item h3 {
  font-size: 20px;
  line-height: 1.5;
}

.promotions-list .promotion-item h3 a {
  color: #333;
}

.promotions-list .promotion-item .btn-theme:hover {
  opacity: .8;
  text-decoration: none;
}

.promotions-list .promotion-item h3 a:hover {
  color: #0775bc;
  text-decoration: none;
}

.promotions-list .promotion-item .item-metas {
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin: 10px 0 15px;
  color: #b5b5b5;
}

.promotions-list .promotion-item .item-metas .expire {
  background-color: #0775bc;
  color: #fff;
  border-radius: 10px;
  padding: 2px 10px;
  margin-right: 5px;
}

.promotions-list .promotion-item .item-metas span i {
  margin-right: 5px;
}

.promotions-list .promotion-item .item-metas .sep {
  color: #333;
  padding: 0 5px;
  display: inline-block;
  font-size: 11px;
  position: relative;
  top: -1px;
}

.badges li {
  display: inline-block;
  margin-right: 15px;
}

.badges li .badge-01 {
  width: 200px;
}

.badges li .badge-02 {
  width: 120px;
}



.panel-group .panel {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-group .panel-title {
  position: relative;
  padding: 15px 30px;
  font-size: 18px;
}
.panel-group .panel-title:before,
.panel-group .panel-title:after {
  position: absolute;
  top: 15px;
  right: 30px;
  font-family: "Web Icons";
  font-size: 12px;
  background-color: #0775bc;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  //-webkit-transition: all 300ms linear 0s;
  //-o-transition: all 300ms linear 0s;
  //transition: all 300ms linear 0s;
}
.panel-group .panel-title:before {
  content: "\f067";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  z-index: 2;
}
.panel-group .panel-title:after {
  content: "\f068";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
//.panel-group .panel-title[aria-expanded="false"]:before {
//  opacity: .4;
//}
.panel-group .panel-title[aria-expanded="false"]:after {
  opacity: 0;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.panel-group .panel-title[aria-expanded="true"]:before {
  opacity: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.panel-group .panel-title[aria-expanded="true"]:after {
  opacity: 1;
}
.panel-group .panel-title:hover,
.panel-group .panel-title:focus {
  color: #76838f;
  text-decoration: none;
}
.panel-group .panel-title:focus {
  outline: none;
}
.panel-group .panel-heading + .panel-collapse {
  margin: 0;
}
.panel-group .panel-collapse .panel-body {
  padding: 15px 30px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top-color: transparent;
}
.panel-group .panel + .panel {
  margin-top: 10px;
}
.panel-group-continuous .panel {
  border-radius: 0;
}
.panel-group-continuous .panel:first-child {
  border-radius: 4px 4px 0 0;
}
.panel-group-continuous .panel:last-child {
  border-radius: 0 0 4px 4px;
}
.panel-group-continuous .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #e4eaec;
}
.panel-group-simple .panel {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-group-simple .panel-title {
  padding-bottom: 10px;
}
.panel-group-simple .panel-title:before,
.panel-group-simple .panel-title:after {
  left: 0px;
}
.panel-group-simple .panel-collapse .panel-body {
  padding-top: 10px;
  padding-right: 0;
  //padding-left: 0;
}
.panel-group-simple .panel + .panel {
  margin-top: 0;
}


// Help center page Manage your money section

.simple-cards .simple-card a {

  background-color: #fff;
  text-align: center;
  display: block;
  -webkit-box-shadow: 0 0 40px rgba(14, 16, 48, 0.035);
  -moz-box-shadow: 0 0 40px rgba(14, 16, 48, 0.035);
  box-shadow: 0 0 40px rgba(14, 16, 48, 0.035);
  padding: 35px 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -ms-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  position: relative;
}

.simple-cards .simple-card a:hover {
  text-decoration: none;

  -webkit-box-shadow: 0 0 40px rgba(14, 16, 48, 0.10);
  -moz-box-shadow: 0 0 40px rgba(14, 16, 48, 0.10);
  box-shadow: 0 0 40px rgba(14, 16, 48, 0.10);
}

.simple-cards .simple-card a:hover h5 {
  color: #7F7ADC;
}

.simple-cards .simple-card h5 {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.simple-cards .simple-card img {
  max-height: 100px;
  margin: 0 auto 20px;
  display: block;
}

.simple-cards .simple-card p {
  margin: 0;
}




// -----
.local-resources .simple-card {
  text-align: center;
}

.local-resources .simple-card h5 a {
  color: #000;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.local-resources .simple-card h5 a:hover {
  text-decoration: none;
  color: #B39DDB;
}

.local-resources .simple-card img {
  max-height: 60px;
  margin: 0 auto 20px;
  display: block;
}


// TESTIMONIALS
.comments {
  margin-bottom: 100px;
}

.comments-list .title {
  margin-bottom: 15px;
}

.comments .comment img {
  margin: 0 0 10px 0;
  height: 24px;
  width: initial;
}

.comments .comment .rating {
  text-align: right;
}

.comments .comment .rating i {
  color: #e66429;
}

.comments .comment .author-name {
  margin: 0 0 10px 0;
  font-weight: bold;
}

.heading-rating {
  text-align: center;
  margin: 0 0 45px 0;
  font-size: 30px;
  color: #e66429;
}

.comments-list .heading {
  margin-bottom: 30px;
}

.homepage .title {
  font-weight: bold;
}


//// Call me MODAL
.modal-dialog {
  margin: 135px auto !important;
}

#callmeModal h3 {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin: 0 0 5px 0;
}


element.style {
}
#callmeModal form button, #modal-newsletter form button {
  border: 0;
  outline: 0;
  padding: 7px 30px;
  margin: 15px auto 0;
  display: table;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

#modal-newsletter .modal-body, #callmeModal .modal-body {
  padding: 35px 100px;
}

#callmeModal .close {
  width: 40px;
  height: 40px;
  background-color: #000;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  opacity: 1;
  outline: none;
}


#callmeModal .modal-body i {
  position: absolute;
  top: 97px;
  left: 114px;
  font-size: 18px;
  color: #c3c3c3;
}

.flash-container .alert {
  margin-bottom: -78px;
  margin-top: 25px;
}

// FB COMMENTS PLUGIN FIX
.fb_iframe_widget_fluid_desktop, .fb_iframe_widget_fluid_desktop span, .fb_iframe_widget_fluid_desktop iframe {
  max-width: 100% !important;
  width: 100% !important;
}


.career-space {
  padding: 60px 0;
}

.career-space p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.intro {
  position: relative;
  z-index: 0;
}

.intro .intro-fixed {
  position: fixed;
  width: 100%;
}

.page {
  background-color: #fff;
  overflow: hidden;
  z-index: 2;
  padding: 0 0 100px;
}

.apply-title {
  font-size: 22px;
  line-height: 1.5;
}

.apply-location {
  padding: 20px;
}

.apply-location i{
  padding: 3px;
}

.apply-attachment-cv {
  padding: 20px 0;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-top: 3px solid #b39ddb;
  margin: 20px 0;
}

.apply ul li::before {
  content: "• ";
  font-size: 20px;
  line-height: 1;
  margin-right: 7px;
  color: #0775bc;
}

.container {
  z-index: 15;
  position: relative;
}

.space {
  padding: 60px 0;
}

.title {
  margin: 0 0 20px;
  font-size: 30px;
  line-height: 38px;
  color: #000;
  font-weight: lighter;
}

.tree {
  margin: 30px 0 0;
  padding: 100px 0;
  position: relative;
}

.tree .tree-right-side {
  text-align: left;
  padding: 0 20px 0 130px;
  position: relative;
}

.tree .tree-left-side {
  text-align: right;
  padding: 0 130px 0 20px;
}

.tree:after {
  position: absolute;
  top: 0;
  right: 50%;
  width: 2px;
  height: 100%;
  margin-right: -8px;
  background-color: #b39ddb;
  border-radius: 100%;
}

.ck-content p {
  margin-top:10px
}

.news .cstm-pagination {
  margin-left:45%;
}

.home-page-intro-video {
  margin-left: 15%;
  margin-top: 3%;
  margin-bottom: 6%;
}

.page .ck-content {
  margin-top: 12px;
}

.disabled-button {
  cursor: not-allowed !important;
}

.search-tabs {
  margin-top: 15px !important;
}

.article-content ol {
  list-style-type: decimal !important;
}

.search-tabs .blog .list .post {
  border-bottom: unset;
  padding: 10px 10px;
}

.search-tabs .blog .list .post .post-img {
  max-height: 400px;
}

.payment-nav {
  display: inline-block;
  font-size: 0;
  margin: 0 0 0 30px;
}

.payment-nav .btn-payment {
  background-color: #b39ddb !important;
  color: #fff;
  border: 1px solid #b39ddb !important;
}

.payment-nav .btn-payment i{
  padding-right: 5px;
}

.payment-nav li a {
  display: block;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 3px;
  text-transform: uppercase;
}

.image-tray {
  margin-top: 10px;
}

.image-tray img {
  width:200px;
  height: 59px;
}

.article-content .image-style-align-right {
  margin-left: 1%;
  float: right;
}

.article-content .image-style-align-left {
  margin-right: 1%;
  float: left;
}

.article-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.article-content .image-style-align-center img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btn-payment-mobile {
  display: table;
  margin: 20px auto;
  padding: 7px 40px;
}

@media only screen and (min-width:0px) and (max-width:450px) {
  .contacts-bar {
    display: none;
  }
}

.about-page-main {
  padding: 0 0 65px;
}

.upload-button {
  background-color: #b39ddb !important;
  color: #fff;
  border: 1px solid #b39ddb !important;
  display: block;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 3px;
  text-transform: uppercase;
  width: 112px;
}

.upload-button:hover {
  text-decoration: none;
  color: #fff;
}

.upload-button-label {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px !important;
}

.upload-button-label i {
  padding-right: 3px;
}

.ck-content h1 {
  line-height: 0.6;
}

.col-md-9 .ck-content ol li {
  margin-left: 20px;
}

.col-md-9 .ck-content ul li {
  margin-left: 20px;
}

.article-blog-content ul li{
  font-size: 14px;
}

.contact-terms-label {
  font-weight: normal;
  display: inline;
}

.applepay{
  padding-top: 70px;
}

.applepay h3 {
  font-size: 28px;
  line-height: 42px;
  color: #163746;
  font-weight: bold;
  margin: 0 0 15px 0;
}

.applepay h5 {
  font-weight: bold;
  margin: 0 0 5px 0;
}

.applepay .applepay-stage {
  margin: 0 0 50px 0;
}

.applepay .applepay-hint {
  text-align: center;
}

.applepay .applepay-hint img {
  margin: 10px auto 0;
  display: block;
  height: 70px;
}

.applepay .applepay-text {
  padding: 0 0 0 50px;
}

.applepay .applepay-text ul li {
  margin: 0 0 10px 0;
}

.applepay .applepay-text a:hover {
  text-decoration: underline;

}.article-content .image-style-align-right {
   margin-left: 1%;
   float: right;
 }

.article-content .image-style-align-left {
  margin-right: 1%;
  float: left;
}

.article-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.article-content .image-style-align-center img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.registration-form-row {
  margin-bottom: 5% !important;
  margin-top: 2% !important;
}

.career-privacy-label {
  display: inline;
}