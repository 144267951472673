@media only screen and (min-width:768px) and (max-width:991px) {
    .megaMenu img,.rate-char {
        display: none;
    }

    header nav .navigaiton li a {
        padding: 5px 10px;
    }

    header nav .navigaitonBtns {
        margin: 0;
    }

    header nav .megaMenuBtn {
        margin-left: 5px;
    }

    .intro,.intro .intro-fixed .carousel .item {
        min-height: 280px;
    }

    .short-contacts .item {
        padding: 35px 0;
    }

    .load_more_button {
        margin-bottom: 30px;
    }

    .article-img {
        margin-top: 40px;
    }

    .contacts-info ul.info li {
        margin-bottom: 15px;
    }

    .contacts-info ul.company-info {
        margin-top: 0;
    }

    .card .title {
        margin-bottom: 20px;
    }

    .card .card-img-mobile {
        max-height: 100px;
        margin: 0 auto 15px;
        display: block;
    }

    .financial-glossary .glossary-filter ul li.active a:after,.intro .scroll-down {
        display: none;
    }

    .intro.about .item {
        padding: 20px 0;
    }

    .features-imgs {
        min-height: 350px;
    }

    .benefits ul {
        margin: 0 0 50px;
    }

    .benefits .benefit.right {
        padding: 0;
    }

    .credit-gauge .credit-gauge-img {
        margin: 50px auto;
    }

    .card .card-item {
        width: 49%;
        display: inline-block;
        text-align: center;
        margin: 0 0 40px;
    }

    .card .card-item figure figcaption {
        font-size: 14px;
        line-height: 1.5;
        display: block;
    }

    .footer-nav {
        margin-bottom: 25px;
    }
}

@media only screen and (min-width:0px) and (max-width:767px) {
    .article .article-title,.credit-gauge h3,.mobile-nav h4,.news .list .post .post-title,.related ul li figure figcaption h3 a {
        font-weight: 700;
    }

    .careers .apply .apply-attachment-cv,.careers .apply .apply-attachment-cv p,.mobile-apply-btn,.mobile-nav h4 {
        text-align: center;
    }

    body {
        font-size: 14px;
        line-height: 23px;
        padding-top: 65px;
    }

    h1 {
        font-size: 34px;
        line-height: 40px;
    }

    h2 {
        font-size: 30px;
        line-height: 35px;
    }

    h3 {
        font-size: 26px;
        line-height: 29px;
    }

    h4 {
        font-size: 22px;
        line-height: 24px;
    }

    h5 {
        font-size: 17px;
        line-height: 19px;
    }

    h6 {
        font-size: 13px;
        line-height: 15px;
    }

    .page-title {
        font-size: 24px;
        line-height: 36px;
    }

    .nav-btn {
        z-index: 10000;
        width: 25px;
        height: 17px;
        cursor: pointer;
        position: relative;
        margin: 25px 5px 0 0;
        display: block;
        float: right;
    }

    .nav-btn span {
        display: block;
        height: 3px;
        background-color: #3e2113;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {
        width: 100%;
    }

    .nav-btn span:nth-child(2) {
        width: 100%;
        top: 7px;
    }

    .nav-btn span:nth-child(3) {
        width: 100%;
        top: 14px;
    }

    .nav-btn:hover span:nth-child(2),.nav-btn:hover span:nth-child(3) {
        width: 100%;
    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #97999b;
        right: -5px;
    }

    .nav-btn.open span:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        top: -3px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 15px;
    }

    .mobile-nav {
        display: none;
    }

    .mobile-nav h4 {
        font-size: 18px;
        line-height: 22px;
    }

    .mobile-nav img {
        margin: 10px auto;
        display: block;
        max-height: 55px;
    }

    .mobile-nav.open {
        display: block;
        padding: 15px 10px;
        position: fixed;
        z-index: 1500;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;

        overflow: auto;
    }

    .mobile-nav.open ul {
        display: inline-block;
        width: 100%;
        margin: 0 0 10px;
    }

    .mobile-nav.open ul.base li:last-of-type a {
        background: linear-gradient(330deg,#1d92de 35%,#9952e0 75%,#9974dc 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .mobile-nav.open ul li {
        text-align: center;
        display: block;
    }

    .mobile-nav.open ul li a:hover,.mobile-nav.open ul li.active a {
        color: #1d92de;
    }

    .mobile-nav.open ul li a {
        display: inline-block;
        font-size: 14px;
        color: #484749;
    }

    .mobile-nav.open ul li a i {
        margin-right: 7px;
    }

    .search input[type=search] {
        height: 40px;
        border-bottom: 1px solid #0071ba;
        border-radius: 0;
        padding: 0 40px 0 0;
    }

    .search button[type=button] {
        display: none;
    }

    .search button[type=submit] {
        line-height: 40px;
        font-size: 20px;
    }

    .mobile-search {
        display: block;
        margin: 10px 30px 0;
    }

    .mobile-apply-btn {
        display: table;
        margin: 13px 0 0;
        padding: 5px 13px;
        width: 100%;
    }

    .contacts-bar {
        top: 40%;
    }

    header .logo img {
        height: 42px;
        margin: 10px 0;
    }

    p {
        font-size: 14px;
        line-height: 1.4;
    }

    .space {
        padding: 30px 0;
    }

    .benefits .benefit.left,.benefits .benefit.right,.tree {
        padding: 0;
    }

    .title {
        font-size: 24px;
        line-height: 26px;
    }

    .card .card-item figure figcaption,.features ul li {
        line-height: 1.5;
        font-size: 14px;
    }

    .card .card-item figure figcaption h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
    }

    .intro {
        min-height: 100px;
    }

    .intro .intro-fixed .carousel .item {
        background-size: 100%!important;
        min-height: 116px;
    }

    .card .title {
        margin-bottom: 20px;
    }

    .card .card-img-mobile {
        max-height: 100px;
        margin: 0 auto 15px;
        display: block;
    }

    .blog .list .post .post-content-container .date-box,.features .features-arrows:after,.features .features-arrows:before,.financial-glossary .glossary-filter ul li.active a:after,.rate-char,.scroll-down,.scrollTop-footer,.tree:after {
        display: none;
    }

    .features ul li:before {
        top: 2px;
    }

    .features-imgs {
        min-height: 200px;
        margin: 15px 0 0;
    }

    .features-phone {
        position: absolute;
        top: 50px;
        left: 0;
        max-height: 160px;
    }

    .features-card {
        position: absolute;
        top: 120px;
        left: 50px;
        max-height: 80px;
    }

    .benefits ul li {
        font-size: 14px;
    }

    .benefits .benefit img {
        height: 100px;
        margin: 0;
    }

    .benefits .benefit h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .benefits .benefit p,.credit-gauge p {
        font-size: 14px;
        line-height: 1.4;
    }

    .credit-gauge h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .credit-gauge .credit-gauge-img {
        max-height: 150px;
        margin: 0 auto 20px;
    }

    .intro.about .about-intro-label {
        font-size: 12px;
        line-height: 16px;
    }

    .intro.about .about-intro-value {
        font-size: 20px;
        line-height: 28px;
    }

    .about .about-text-first h2,.about .about-text-second h2 {
        margin: 0 0 5px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
    }

    .about .about-text-first {
        margin-bottom: 20px;
    }

    .about .about-text-second,.short-contacts .item {
        margin-bottom: 50px;
    }

    .short-contacts ul li:last-of-type .item {
        margin-bottom: 0;
    }

    .tree .tree-left-side {
        padding-left: 0;
        margin: 0 0 30px;
    }

    .tree .tree-right-side {
        margin: 0 0 30px;
        padding-right: 0;
    }

    .career-moto p {
        font-size: 20px;
        line-height: 24px;
        padding: 0 20px;
    }

    .blog .list .post .post-content-container,.blog aside {
        padding: 0;
    }

    .load_more_button {
        margin-bottom: 30px;
    }

    .blog .list .post .post-img figure img {
        position: relative;
        top: 0;
    }

    .blog .list .post .post-img {
        height: inherit;
    }

    .blog .list .post {
        padding: 30px 0;
    }

    .article .article-title {
        font-size: 20px;
        line-height: 26px;
    }

    .post-img {
        margin: 0 0 15px;
    }

    aside.sidebar {
        margin-bottom: 30px;
    }

    .article .article-img figure img {
        max-width: 100%;
    }

    .contacts-info ul.info li {
        margin-bottom: 15px;
    }

    .contacts-info ul.info li:last-of-type {
        margin-bottom: 0;
    }

    .contacts-info ul.company-info {
        margin-top: 0;
    }

    #rates .modal-header .close {
        margin-top: -22px;
    }

    .intro.about .item {
        padding: 13px 0;
    }

    .intro.about h1 {
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 5px;
    }

    .careers .apply .apply-attachment-cv input[type=file] {
        top: 0;
        margin: 10px 15px;
    }

    footer {
        padding: 20px 0 10px;
    }

    footer h4 {
        margin: 0;
        font-size: 14px;
    }

    footer .footer-nav {
        margin: 0 0 20px;
    }

    footer .certificates li {
        background-size: 130px 40px;
        width: 130px;
        height: 44px;
    }

    footer .subscribe {
        margin-top: 6px;
    }

    #CookiePolicy p {
        margin-bottom: 0;
        font-size: 10px;
        line-height: 12px;
        padding: 6px 0;
        color: #fff;
        display: inline-block;
    }

    .badges li .badge-01 {
        width: 120px;
    }

    .badges li .badge-02 {
        width: 80px;
    }

    .card .card-item figure img {
        margin-left: 0;
    }
}